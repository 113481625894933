<template>
  <section>
    <b-overlay :show="loadedHangarVariantLoading">
      <div class="d-flex justify-content-end mb-2">
        <b-button :to="{ name: 'hangar-dashboard', query: { uniqueKey: `${Date.now()}` }}">
          {{ $t('dashboard.hangar.view_dashboard') }}
        </b-button>
      </div>
      <b-card v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'hangar' || loadedHangarVariant.variantType === 'outside_ramps') && $route.query.mode === 'timeGridDay'" class="border">
        <b-card-title>{{ $t('hangar.availability') }}</b-card-title>
        <div class="d-flex flex-column flex-sm-row justify-content-between">
          <div class="flex-column">
            <div v-for="slice in infoSlicesDay.slice(0, slicesNumber /2)" :key="slice.sliceNumber" class="mb-25 pr-sm-1">
              {{ $t('dashboard.description_slice', { reference: slice.icao + ' - ' + slice.name, sliceNumber: slice.sliceNumber, from: slice.footPrintScaleFrom, to: slice.footPrintScaleTo, max: slice.maxAircraftPlaces, places: (getAvailability(slice)) }) }}
            </div>
          </div>
          <div class="flex-column">
            <div v-for="slice in infoSlicesDay.slice(slicesNumber /2, slicesNumber)" :key="slice.sliceNumber" class="mb-25 pr-sm-1">
              {{ $t('dashboard.description_slice', { reference: slice.icao + ' - ' + slice.name, sliceNumber: slice.sliceNumber, from: slice.footPrintScaleFrom, to: slice.footPrintScaleTo, max: slice.maxAircraftPlaces, places: (getAvailability(slice)) }) }}
            </div>
          </div>
        </div>
      </b-card>
      <b-card v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'parking') && $route.query.mode === 'timeGridDay' && calendarData" class="border">
        <b-card-title>{{ $t('hangar.availability') }}</b-card-title>
        <div class="d-flex flex-column flex-sm-row justify-content-between">
          <div v-if="calendarData.parkingSliceType === 'classic'" class="flex-column">
            <div class="mb-25 pr-sm-1">
              {{ $t('dashboard.parking.Availability') }} : {{ calendarData.maxAircraftPlaces ? calendarData.maxAircraftPlaces : 0 }}
            </div>
            <div class="mb-25 pr-sm-1">
              {{ $t('dashboard.parking.used') }} : {{ calendarData.numberOfAircrafts ? calendarData.maxAircraftPlaces - calendarData.numberOfAircrafts : calendarData.maxAircraftPlaces }}
            </div>
          </div>
          <div v-if="calendarData.parkingSliceType === 'slice'" class="flex-column">
            <div v-for="(slice, index) in infoSlicesDay" :key="slice.sliceNumber" class="mb-25 pr-sm-1">
              {{ $t('dashboard.table.slice') }} {{ index + 1 }}: {{ $t('common.from') }} {{ slice.fromQuantity }} {{ $t('common.to') }} {{ slice.toQuantity }} {{ calendarData.parkingSliceUnit|enumTranslate('parking_slice_unit') }} ({{ $t('dashboard.parking.Maximum') }} {{ slice.availableSpots }}) :  {{ getParkingAvailability(slice) }}
            </div>
          </div>
        </div>
      </b-card>

      <b-card class="border mb-0">
        <div class="d-block d-sm-flex">
          <div class="flex-column pr-2">
            <app-btn-add v-if="$can('DASHBOARD_HANGAR_ADD')" @click="openAddSidebar">{{ $t('trip.sidebar.add_an_event') }}</app-btn-add>

            <!-- Choix du service à visualiser, sauvegardé dans la selectedHangarVariant -->
            <app-select
              id="hangarVariantId"
              v-model="selectedHangarVariant"
              class="my-2"
              style="width: 200px"
              :placeholder="$t('dashboard.hangar.hangar')"
              :options="hangarsVariantsOptions"
              :searchable="false"
              :clearable="false"
              v-bind="variantsDataMeta"
              paginated
              :overlay="hangarsVariantsOptionsLoading"
              @option:selected="fetchHangarsVariantsDashboard()"
              @click:previous-page="fetchHangarsVariants(variantsDataMeta.previousPage)"
              @click:next-page="fetchHangarsVariants(variantsDataMeta.nextPage)"
            >
              <template #selected-option="{ productName }">
                {{ productName | trans }}
              </template>
              <template #option="{ productName }">
                {{ productName | trans }}
              </template>
            </app-select>

            <template v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'hangar' || loadedHangarVariant.variantType === 'outside_ramps')">
              <div v-if="slicesNumber > 1" class="mt-1">
                <b-form-checkbox
                  :checked="selectedSlices.length === slicesNumber"
                  stacked
                  @change="selectAllSlices()"
                >
                  <div class="">{{ $t('common.all') }}</div>
                </b-form-checkbox>
              </div>
              <div v-for="(elem, index) in slicesNumber" :key="index">
                <div class="mt-1">
                  <b-form-checkbox
                    v-model="selectedSlices"
                    :value="index + 1"
                    stacked
                  >
                      <div class="">{{ $t('dashboard.table.slice') }} {{ index + 1 }} : {{ slices[index + 1].icao }} - {{ slices[index + 1].name }}</div>
                  </b-form-checkbox>
                </div>
              </div>
            </template>
            <template v-if="loadedHangarVariant && loadedHangarVariant.variantType === 'parking' && loadedHangarVariant.managementType === 'slice'">
              <div v-if="infoSlicesDay.length > 1" class="mt-1">
                <b-form-checkbox
                  :checked="infoSlicesDay.length === parkingSelectedSlices.length"
                  stacked
                  @change="selectAllParkingSlices()"
                >
                  <div class="">{{ $t('common.all') }}</div>
                </b-form-checkbox>
              </div>
              <div v-for="(elem, index) in infoSlicesDay" :key="index">
                <div class="mt-1">
                  <b-form-checkbox
                    v-model="parkingSelectedSlices"
                    :value="elem.id"
                    stacked
                  >
                    <div class="">{{ $t('dashboard.table.slice') }} {{ index + 1 }}</div>
                  </b-form-checkbox>
                </div>
              </div>
            </template>
          </div>

          <div class="flex-grow-1 mb-2 hangar-full-calendar-wrapper">
            <full-calendar id="dashboard-calendar" ref="fullCalendar" :options="calendarOptions" class="hangar-full-calendar full-calendar" />
          </div>
        </div>
      </b-card>

      <app-sidebar-form
        form-name="add-event"
        :edition-mode-title="eventMode"
        :edition="!!eventMode"
        :title="$tc('common.aircraft', 1)"
        :external-request="onSubmit"
        :entity="eventEntity"
        :show-submit="$can('DASHBOARD_HANGAR_EDIT')"
        :show-reset="eventMode === 'edit' && $can('DASHBOARD_HANGAR_EDIT')"
        :show-delete="eventMode === 'edit' && $can('DASHBOARD_HANGAR_DELETE')"
        @delete="modalDeletecustomTrip(eventEntity.id)"
        @update:edition="val => {
          if (!val) {
            eventMode = null
            eventEntity = eventEntityInit
          }
        }"
      >
        <template #fields="{ item }">
          <div v-if="eventMode === 'edit'" class="mb-2">
            <div class="mb-2">{{ $tc('common.aircraft', 1) }}: <span v-if="item.icao">{{ item.icao }} -</span> {{ item.title }}</div>
            <div v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'hangar' || loadedHangarVariant.variantType === 'outside_ramps')" class="mb-2">{{ $t('dashboard.table.slice') }}: {{ item.sliceNumber }}</div>
          </div>

          <template v-else>
              <app-input
                  id="icao"
                  v-model="item.icao"
                  :label="$t('dashboard.table.icao')"
                  autofocus
                  required
              />
            <app-input
              id="title"
              v-model="item.title"
              :label="$tc('common.aircraft', 1)"
              required
            />
            <validation-provider #default="{}" :name="$t('dashboard.table.slice')" rules="required" class="validation-required">
              <b-form-group
                v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'hangar' || loadedHangarVariant.variantType === 'outside_ramps')"
                :label="$t('dashboard.table.slice')"
                label-for="slice"
              >
                <v-select
                  v-model="item.sliceNumber"
                  input-id="slice"
                  :options="sliceNumberOptions"
                  :clearable="false"
                />
              </b-form-group>
            </validation-provider>
          </template>

          <!-- Parking slice mtow -->
          <app-input
            v-if="loadedHangarVariant
             && loadedHangarVariant.variantType === 'parking'
              && loadedHangarVariant.managementType === 'slice'
              && loadedHangarVariant.sliceUnit === 'mtow'"
            id="slice-parking"
            v-model="item.sliceMtowValue"
            :label="$t('dashboard.table.parking_slice.mtow')"
            :append="$getMeasurementUnitItn('kg')"
            autofocus
            required
            :formatter="numberFormat"
          />

          <!-- Parking slice wingspan -->
          <app-input
            v-if="loadedHangarVariant
             && loadedHangarVariant.variantType === 'parking'
              && loadedHangarVariant.managementType === 'slice'
              && loadedHangarVariant.sliceUnit === 'wingspan'"
            id="slice-parking"
            v-model="item.sliceWingspanValue"
            :label="$t('dashboard.table.parking_slice.wingspan')"
            :append="$getMeasurementUnitItn('m')"
            autofocus
            required
            :formatter="numberFormat"
          />

          <validation-provider #default="{}" :name="$t('dashboard.start_date')" rules="required" class="validation-required">
            <b-form-group :label="$t('dashboard.start_date')" class="mb-1" label-for="dateStart">
              <flat-pickr
                id="dateStart"
                v-model="item.startAt"
                :config="{
                  altInput: true,
                  altFormat: 'F j, Y H:i',
                  dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                  enableTime: true,
                  time_24hr: true,
                  defaultDate: item.startAt,
                  locale: $i18n.locale,
                  // Permet d'avoir la date affichée en UTC
                  onReady: (selectedDates, dateStr, instance) => {
                    if (selectedDates[0]) {
                      instance.setDate(new Date(selectedDates[0].toISOString()), false);
                    }
                  },
                }"
                class="form-control"
                required
              />
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{}" :name="$t('dashboard.end_date')" rules="required" class="validation-required">
            <b-form-group :label="$t('dashboard.end_date')" class="mb-1" label-for="dateEnd">
              <flat-pickr
                id="dateEnd"
                v-model="item.endAt"
                :config="{
                  altInput: true,
                  altFormat: 'F j, Y H:i',
                  dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                  enableTime: true,
                  time_24hr: true,
                  defaultDate: item.endAt,
                  locale: $i18n.locale,
                  // Permet d'avoir la date affichée en UTC
                  onReady: (selectedDates, dateStr, instance) => {
                    if (selectedDates[0]) {
                      instance.setDate(new Date(selectedDates[0].toISOString()), false);
                    }
                  },
                }"
                class="form-control"
                required
              />
            </b-form-group>
          </validation-provider>

          <validation-provider>
            <b-form-group :label="$t('dashboard.customer')" label-for="customer" class="mb-0">
              <v-select
                v-model="item.customerContract"
                input-id="customer"
                :get-option-label="option => option.companyName"
                :options="[{ companyName: $t('dashboard.custom'), id: null }].concat(negotiatedRates)"
                :clearable="false"
                :disabled="!!item.id"
                @option:selected="item.clientName = null"
              />
            </b-form-group>
            <div class="mb-2"><i>{{ $t('dashboard.customer_notice') }}</i></div>
          </validation-provider>

          <app-input
            v-if="!item.customerContract.id"
            id="clientName"
            v-model="item.clientName"
            :label="$t('dashboard.custom')"
            :disabled="!!item.id"
          />

          <validation-provider>
            <b-form-group :label="$t('dashboard.internal_description')" label-for="internalDescription">
              <b-form-textarea
                id="internalDescription"
                v-model="item.internalDescription"
                :placeholder="$t('dashboard.internal_description')"
                rows="5"
              />
              <span><i>{{ $t('dashboard.internal_description_notice') }}</i></span>
            </b-form-group>
          </validation-provider>
        </template>
      </app-sidebar-form>
    </b-overlay>
  </section>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { omit, pick } from 'lodash'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import UIHelper from '@/helpers/ui'

import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppDatepicker from '@/components/AppDatepicker.vue'
import AppSelect from '@/components/AppSelect.vue'
import {
  deleteHangarCustomEventRequest,
  fetchHangarCustomEventRequest,
  fetchHangarsVariantsCalendarRequest,
  fetchHangarsVariantsCalendarSlicesRequest,
  fetchHangarVariantRequest,
  patchHangarCustomEventRequest,
  postHangarCustomEventRequest,
} from '@/request/globalApi/requests/hangarRequests'

export default defineComponent({
  name: 'DashboardCalendar',

  components: {
    AppBtnAdd,
    AppSidebarForm,
    AppDatepicker,
    AppSelect,
    FullCalendar,
  },

  setup(_props, ctx) {
    // eslint-disable-next-line object-curly-newline
    const { $i18n, $moment, $store, $router, $can, $bvModal, _cloneDeep } = ctx.root

    const getDay = day => (day ? $moment(day).format('YYYY-MM-DD') : null)
    const currentDate = ref($moment().locale($i18n.locale))

    // For v-select paginated
    const variantsDataMeta = computed(() => pick($store.state.hangarVariant, 'previousPage', 'nextPage', 'lastPage', 'totalItems'))

    const queries = ref({})
    const calendarData = ref(null)

    const infoSlicesDay = ref([])
    const firstLoad = ref(true)

    // Map state
    const slicesNumber = computed(() => $store.state.hangarVariant.slicesNumber)
    const slices = computed(() => $store.state.hangarVariant.slices)
    const hangarsVariantsOptions = computed(() => $store.state.hangarVariant.hangarsVariantsOptions)
    const hangarsVariantsOptionsLoading = computed(() => $store.state.hangarVariant.hangarsVariantsOptionsLoading)
    const negotiatedRates = computed(() => $store.state.negotiatedRate.negotiatedRates)
    const selectedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.selectedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangarVariant)
      },
    })

    const loadedHangarVariantLoading = computed({
      get() {
        return $store.state.hangarVariant.loadedHangarVariantLoading
      },
      set(isLoading) {
        $store.commit('hangarVariant/SET_LOADED_HANGAR_VARIANT_LOADING', isLoading)
      },
    })

    const loadedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.loadedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_LOADED_HANGAR_VARIANT', hangarVariant)
      },
    })

    const selectedSlices = computed({
      get() {
        return $store.state.hangarVariant.selectedSlices
      },
      set(slices) {
        $store.commit('hangarVariant/SET_SELECTED_SLICES', slices)
      },
    })
    // Init toujours à vide
    $store.commit('hangarVariant/SET_SELECTED_PARKING_SLICES', [])
    const parkingSelectedSlices = computed({
      get() {
        return $store.state.hangarVariant.parkingSelectedSlices
      },
      set(slices) {
        $store.commit('hangarVariant/SET_SELECTED_PARKING_SLICES', slices)
      },
    })

    const sliceNumberOptions = computed(() => {
      const options = []
      for (let i = 1; i <= slicesNumber.value; i++) {
        options.push(i)
      }
      return options
    })

    const fetchHangarsVariantsDashboard = (variantId = ctx.root.$route.query.vid, queryParams = {}) => {
      const query = {
        date: $moment(currentDate.value).locale('en').format('DD-MM-YYYY'),
        ...queryParams,
      }
      $store.dispatch('hangarVariant/fetchHangarsVariantsDashboard', { variantId, queryParams: query })
    }

    const eventEntity = ref({
      title: null,
      variantId: null,
      sliceNumber: null,
      sliceMtowValue: null,
      sliceWingspanValue: null,
      startAt: null,
      endAt: null,
      customerContract: { companyName: $i18n.t('dashboard.custom'), id: null },
      clientName: null,
      internalDescription: null,
    })
    const eventEntityInit = ref(_cloneDeep(eventEntity.value))

    const eventMode = ref(null)
    const hangarEditLoading = ref(false)

    const selectAllSlices = () => {
      if (selectedSlices.value.length === infoSlicesDay.value.length) selectedSlices.value = []
      else {
        const slices = []
        infoSlicesDay.value.forEach(slice => {
          slices.push(slice.sliceNumber)
        })
        $store.commit('hangarVariant/SET_SELECTED_SLICES', slices)
      }
    }

    const selectAllParkingSlices = () => {
      if (parkingSelectedSlices.value.length === infoSlicesDay.value.length) parkingSelectedSlices.value = []
      else {
        const slices = []
        infoSlicesDay.value.forEach(slice => {
          slices.push(slice.id)
        })
        $store.commit('hangarVariant/SET_SELECTED_PARKING_SLICES', slices)
      }
    }

    $store.commit('negotiatedRate/SET_NEGOCIATED_RATES_LIGHT_MODE', true)
    $store.dispatch('negotiatedRate/fetchNegotiatedRates', true)

    $store.dispatch('hangarVariant/fetchHangarSlicesNumber')

    const fetchHangarsVariants = page => {
      const neededRefresh = page && $store.state.hangarVariant.page !== page && !hangarsVariantsOptions[0]
      if (page) $store.commit('hangarVariant/SET_PAGE', page)
      $store.dispatch('hangarVariant/fetchHangarsVariants', neededRefresh).then(() => {
        if (hangarsVariantsOptions.value[0] && !page) {
          let hangar = hangarsVariantsOptions.value[0]

          // If a hangarVariantId is preselected by the url, select it instead of the store's selectedHangarVariant, only if it available in the options
          if (ctx.root.$route.query.vid) {
            hangar = hangarsVariantsOptions.value.find(hangarVariant => hangarVariant.id === Number(ctx.root.$route.query.vid)) || hangar
          }
          $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangar)
        }
      })
    }
    fetchHangarsVariants()

    const fullCalendar = ref(null)

    const calendarOptions = reactive({
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      initialView: ctx.root.$route.query.mode,
      headerToolbar: {
        start: 'sidebarToggle, prev, next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,today',
      },
      dayMaxEventRows: true,
      eventMaxStack: 3,
      moreLinkContent(args) {
        return $i18n.t('dashboard.hangar.more', { count: args.num })
      },
      views: {
        dayGridMonth: { displayEventEnd: true },
      },
      events: [],
      datesSet: event => {
        if (getDay(event.start) === queries.value.start
          && queries.value.mode === event.view.type
        ) return

        queries.value = {
          vid: ctx.root.$route.query.vid || selectedHangarVariant.value?.id,
          start: getDay(event.start),
          end: getDay(event.end),
          mode: event.view.type,
        }
        $router.replace({ query: { ...ctx.root.$route.query, ...queries.value, end: undefined, uniqueKey: `${Date.now()}` } })
      },
      eventClick: calEvent => {
        if (calEvent.event.extendedProps.isCustom) {
          if ($can('DASHBOARD_HANGAR_EDIT')) {
            hangarEditLoading.value = true
            fetchHangarCustomEventRequest(calEvent.event.id).then(({ data }) => {
              eventEntity.value = {
                ...omit(data, 'slice', 'customerContract'),
                customerContract: data.customerContract || eventEntity.value.customerContract,
              }
              if (loadedHangarVariant.value.variantType === 'hangar' || loadedHangarVariant.value.variantType === 'outside_ramps') {
                eventEntity.value.sliceNumber = data.slice.sliceNumber
                delete eventEntity.value.sliceMtowValue
                delete eventEntity.value.sliceWingspanValue
              } else {
                if (loadedHangarVariant.value.managementType === 'slice' && loadedHangarVariant.value.sliceUnit === 'mtow') {
                  eventEntity.value.sliceMtowValue = data.sliceMtowValue
                } else if (loadedHangarVariant.value.managementType === 'slice' && loadedHangarVariant.value.sliceUnit === 'wingspan') {
                  eventEntity.value.sliceWingspanValue = data.sliceWingspanValue
                }
                delete eventEntity.value.sliceNumber
              }

              openEditSidebar()
              hangarEditLoading.value = false
            })
          }
        } else {
          $router.push({ name: 'trip-details', params: { trip_id: calEvent.event.id } })
        }
      },
      textEscape: false,
      eventResizableFromStart: true,
      fixedWeekCount: false,
      weekNumberCalculation: 'ISO',
      navLinks: true,
      eventTimeFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      slotLabelFormat: { // like '14:30:00'
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        meridiem: false,
      },
      locale: $i18n.locale,
      stickyHeaderDates: true,
    })

    const fetchHangarsVariantsCalendar = async (variantId = selectedHangarVariant.value?.id) => {
      const query = {
        ...pick(queries.value, 'start', 'end'),
        sliceNumbers: selectedSlices.value,
        parkingSliceNumbers: parkingSelectedSlices.value,
      }
      return fetchHangarsVariantsCalendarRequest(variantId, query).then(({ data }) => {
        calendarData.value = data
        // Reset des événements
        calendarOptions.events = []

        data.aircrafts.forEach(aircraft => {
          const tripId = aircraft.tripId || aircraft.customEventId
          const start = $moment(aircraft.start).utc()
          const end = $moment(aircraft.end).utc()
          const backgroundColor = aircraft.quoteStatus !== null ? UIHelper.methods.resolveQuoteStateColor(aircraft.quoteStatus) : 'rgba(56, 77, 115, 0.8)'
          const textColor = aircraft.quoteStatus !== null ? UIHelper.methods.resolveQuoteStateTextColor(aircraft.quoteStatus) : '#ffffff'
          // Si le mode dans calendarOptions est semaine ou jour, et que l'événement est sur plusieurs jours ou que c'est la même journée mais de 00h à 00h, on affiche le premier jour et dernier jour avec allDay à false pour que les heures soient prises en compte. Puis tous les jours intermédiaires sont compris dans le même événement avec l'option allDay à true
          if (queries.value.mode === 'timeGridWeek' || queries.value.mode === 'timeGridDay') {
            // Si la range est > 1 jour
            if (start.format('YYYY-MM-DD') !== end.format('YYYY-MM-DD')) {
              const firstDayAll = start.format('HH:mm') === '00:00'
              const lastDayAll = end.format('HH:mm') === '00:00'

              // Si ma date de début ne commence pas à 00h, alors je l'ajoute pour les heures
              if (firstDayAll === false) {
                calendarOptions.events.push({
                  title: aircraft.title,
                  id: tripId,
                  groupId: tripId,
                  isCustom: !!aircraft.customEventId,
                  start: start.format('YYYY-MM-DD HH:mm'),
                  end: start.clone().endOf('day').format('YYYY-MM-DD HH:mm'),
                  color: backgroundColor,
                  textColor: textColor,
                  allDay: false,
                })
              }

              // Si ma date de fin ne termine pas à 00h, alors je l'ajoute pour les heures
              if (lastDayAll === false) {
                calendarOptions.events.push({
                  title: aircraft.title,
                  id: tripId,
                  groupId: tripId,
                  isCustom: !!aircraft.customEventId,
                  start: end.clone().startOf('day').format('YYYY-MM-DD HH:mm'),
                  end: end.format('YYYY-MM-DD HH:mm'),
                  color: backgroundColor,
                  textColor: textColor,
                  allDay: false,
                })
              }

              // Si ma range de date est supérieure à 2 jours ou que c'est 2 jours mais que le premier et 2ème jour sont tous les 2 à 00h, alors je l'ajoute pour les jours
              const startNextDay = start.clone().add(1, 'day')
              const endNextDay = end.clone().add(1, 'day')
              if ($moment(startNextDay).format('YYYY-MM-DD') !== end.format('YYYY-MM-DD') || (firstDayAll && lastDayAll)) {
                calendarOptions.events.push({
                  title: aircraft.title,
                  id: tripId,
                  groupId: tripId,
                  isCustom: !!aircraft.customEventId,
                  start: firstDayAll ? start.format('YYYY-MM-DD HH:mm') : startNextDay.format('YYYY-MM-DD HH:mm'),
                  end: lastDayAll ? endNextDay.format('YYYY-MM-DD HH:mm') : end.format('YYYY-MM-DD HH:mm'), // Inverse du start
                  color: backgroundColor,
                  textColor: textColor,
                  allDay: true,
                })
              }
            } else {
              calendarOptions.events.push({
                title: aircraft.title,
                id: tripId,
                groupId: tripId,
                isCustom: !!aircraft.customEventId,
                start: start.format('YYYY-MM-DD HH:mm'),
                end: end.format('YYYY-MM-DD HH:mm'),
                color: backgroundColor,
                textColor: textColor,
                allDay: start.format('HH:mm') === '00:00' && end.format('HH:mm') === '00:00',
              })
            }
          } else {
            const endDay = end.add(1, 'day')
            calendarOptions.events.push({
              title: aircraft.title,
              id: tripId,
              groupId: tripId,
              isCustom: !!aircraft.customEventId,
              start: start.format('YYYY-MM-DD HH:mm'),
              end: $moment(endDay).endOf('day').format('YYYY-MM-DD HH:mm'),
              color: backgroundColor,
              textColor: textColor,
              allDay: true,
            })
          }
        })
      })
    }

    const modalDeletecustomTrip = customEventId => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(ok => {
          if (ok) {
            deleteHangarCustomEventRequest(customEventId).then(() => {
              eventMode.value = null
              fetchHangarsVariantsCalendar()
            })
          }
        })
    }

    const openAddSidebar = () => {
      reloadHangarVariant('add')
    }

    const openEditSidebar = () => {
      reloadHangarVariant('edit')
    }

    watch([parkingSelectedSlices, selectedSlices, () => ctx.root.$route.query], () => {
      fetchHangarsVariantsCalendar()
    })

    watch([selectedHangarVariant, () => ctx.root.$route.query], () => {
      eventEntity.value.variantId = selectedHangarVariant.value?.id
      if (selectedHangarVariant.value) {
        eventEntity.value.variantId = selectedHangarVariant.value.id
        eventEntityInit.value.variantId = selectedHangarVariant.value.id
        if (!queries.value.start) {
          // Première fois qu'on arrive sur la page, si le start n'est pas défini, on refresh en ajoutant le start afin de passer dans le else
          $router.replace({ query: { ...ctx.root.$route.query, vid: selectedHangarVariant.value.id, uniqueKey: `${Date.now()}` } })
        } else {
          // Chargement des données à jour du hangar lorsqu'on charge la première fois ou que l'on change dans le select
          reloadHangarVariant()
        }
      }
    }, { immediate: true })

    const reloadHangarVariant = (changeEventMode = null) => {
      loadedHangarVariantLoading.value = true
      fetchHangarVariantRequest(selectedHangarVariant.value.id).then(({ data }) => {
        loadedHangarVariant.value = data

        // Chargement des tranches du hangar s'il y en a
        fetchHangarsVariantsCalendarSlicesRequest(selectedHangarVariant.value.id, {date: queries.value.start}).then(({data}) => {
          infoSlicesDay.value = data.slices
          if (firstLoad.value) {
            firstLoad.value = false
          }

          parkingSelectedSlices.value = []
          selectedSlices.value = []
          // Initialisation des filtres avec le ALL par défaut
          if (loadedHangarVariant.value.variantType !== 'parking') {
            selectAllSlices()
          } else if (loadedHangarVariant.value.variantType === 'parking') {
            selectAllParkingSlices()
          }
        })
      }).finally(() => {
        loadedHangarVariantLoading.value = false
        if (changeEventMode) {
          eventMode.value = changeEventMode
        }
      })
    }

    const onSubmit = async payload => {
      let action = patchHangarCustomEventRequest
      const parsePayload = {
        ...omit(payload, 'customerContract'),
        customerContractId: payload.customerContract.id,
      }

      if (eventMode.value === 'add') action = postHangarCustomEventRequest
      return action(parsePayload).then(() => {
        fetchHangarsVariantsCalendar()
        if (ctx.root.$route.query.mode === 'timeGridDay') {
          const queryParams = { date: queries.value.start }
          fetchHangarsVariantsCalendarSlicesRequest(selectedHangarVariant.value.id, queryParams).then(({ data }) => {
            infoSlicesDay.value = data.slices
            if (firstLoad.value) {
              firstLoad.value = false
              selectAllParkingSlices()
            }
          })
        }
      })
    }

    const getAvailability = slice => {
      if (slice.availabilityAircraftPlaces > slice.maxAircraftPlaces) {
        return slice.maxAircraftPlaces
      }

      return slice.availabilityAircraftPlaces
    }

    const getParkingAvailability = slice => {
      if (slice.availabilityAircraftPlaces > slice.availableSpots) {
        return slice.availableSpots
      }

      return slice.availabilityAircraftPlaces
    }


    const numberFormat = value => value.replace(/,/g, '.')

    return {
      negotiatedRates,
      hangarsVariantsOptions,
      hangarsVariantsOptionsLoading,
      fetchHangarsVariantsDashboard,
      slicesNumber,
      slices,
      sliceNumberOptions,
      selectedSlices,
      selectAllSlices,
      calendarOptions,
      fullCalendar,
      eventEntity,
      eventEntityInit,
      eventMode,
      hangarEditLoading,
      selectedHangarVariant,
      variantsDataMeta,
      infoSlicesDay,
      fetchHangarsVariants,
      fetchHangarsVariantsCalendar,
      modalDeletecustomTrip,
      onSubmit,
      getAvailability,
      getParkingAvailability,
      calendarData,
      numberFormat,
      selectAllParkingSlices,
      parkingSelectedSlices,
      loadedHangarVariantLoading,
      loadedHangarVariant,
      reloadHangarVariant,
      openAddSidebar,
      openEditSidebar,
    }
  },
})
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100%!important;
}
.fc-scrollgrid-sync-table{
  width: 100%!important;
}
.fc-col-header {
  width: 100%!important;
}
.fc-addTarifsBtn-button {
  border-color: #DCC181 !important;
  background-color: #DCC181 !important;
}
div.fc-header-toolbar.fc-toolbar > div:nth-child(1) > div:nth-child(1) > button.fc-sidebarToggle-button.fc-button.fc-button-primary {
  display: none;
}
</style>
